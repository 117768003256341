
export default {
  name: 'PlacementStatistics',
  props: {
    slice: { type: Object, required: true },
  },
  computed: {
    sectors() {
      return [
        this.slice.primary.sector1,
        this.slice.primary.sector2,
        this.slice.primary.sector3,
        this.slice.primary.sector4,
        this.slice.primary.sector5,
      ]
    },
  },
}
